import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useApiRequest from "../components/fetch/useApiRequest";
import {FaArrowLeft} from "react-icons/fa";
import '../static/OrderDetail.css';
import Loading from "../components/Loading";

const OrderDetail = () => {
    const {orderId} = useParams();
    const navigate = useNavigate();
    const [order, setOrder] = useState(null);
    const {getApiRequest, loading} = useApiRequest();
    const token = localStorage.getItem('access_token');
    // USDT 금액 포맷팅 함수
    const formatUSDT = (amount) => {
        if (typeof amount === 'string') {
            amount = parseFloat(amount);
        }
        let [integerPart, decimalPart] = amount.toFixed(4).split('.');
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${integerPart}.${decimalPart}`;
    };

    const formatKrw = (amount) => {
        if (typeof amount === 'string') {
            amount = parseFloat(amount);
        }
        let [integerPart, decimalPart] = amount.toFixed(1).split('.');
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${integerPart}.${decimalPart}`;
    };

    useEffect(() => {
        const fetchOrderDetail = async () => {
            try {
                await getApiRequest(
                    `/orders/${orderId}`,
                    {},
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    (data) => {
                        console.log(data)
                        setOrder(data);
                    },
                    (error) => {
                        console.error('주문 상세 정보를 불러오는 데 실패했습니다:', error);
                    }
                );
            } catch (error) {
                console.error('주문 상세 정보 요청 중 오류가 발생했습니다:', error);
            }
        };

        fetchOrderDetail();
        // eslint-disable-next-line
    }, []);

    const handleGoBack = () => {
        navigate(-1);
    };

    if (!order) {
        return <div className="error-message">주문 정보를 불러올 수 없습니다.</div>;
    }


    return (
        <>
            {loading ? <Loading isFullScreen={true}/> : (
                <div className="order-detail-container">
                    <div className="order-detail-header">
                        <FaArrowLeft onClick={handleGoBack} className="back-icon"/>
                        <h1 className="order-detail-title">주문 상세 정보</h1>
                    </div>

                    {/* 주문 기본 정보 */}
                    <div className="order-detail-card">
                        <h2 className="card-title">주문 정보</h2>
                        <div className="order-info">
                            <div className="order-info-row">
                                <span>주문 ID:</span> <span>{order.id}</span>
                            </div>
                            <div className="order-info-row">
                                <span>사용자:</span> <span>{order.user?.username}</span>
                            </div>
                            <div className="order-info-row">
                                <span>주문 상태:</span> <span>{order.status}</span>
                            </div>
                            <div className="order-info-row">
                                <span>주문 수량:</span> <span>{formatUSDT(order.amount)} USDT</span>
                            </div>
                            <div className="order-info-row">
                                <span>주문 가격:</span> <span>{formatKrw(order.price)} 원</span>
                            </div>
                            <div className="order-info-row">
                                <span>주문 금액:</span> <span>{formatKrw(order.amount*order.price)} 원</span>
                            </div>
                            <div className="order-info-row">
                                <span>생성 날짜:</span> <span>{new Date(order.created_at).toLocaleString()}</span>
                            </div>
                            <div className="order-info-row">
                                <span>수정 날짜:</span> <span>{new Date(order.updated_at).toLocaleString()}</span>
                            </div>
                            <div className="order-info-row full-width">
                                <span>오류 :</span> <span>{order.remark}</span>
                            </div>
                        </div>
                    </div>

                    {/* Binance 주문 정보 */}
                    {order.binance_withdrawal && (
                        <div className="order-detail-card">
                            <h2 className="card-title">Binance 주문 정보</h2>
                            <div className="order-info">
                                <div className="order-info-row full-width">
                                    <span>전송 ID:</span> <span>{order.binance_withdrawal?.transaction_id}</span>
                                </div>
                                <div className="order-info-row">
                                    <span>전송수량:</span> <span>{formatUSDT(order.binance_withdrawal?.amount)} USDT</span>
                                </div>
                                <div className="order-info-row">
                                    <span>상태:</span> <span>{order.binance_withdrawal?.status}</span>
                                </div>
                                <div className="order-info-row full-width">
                                    <span>전송주소:</span> <span>{order.binance_withdrawal?.address}</span>
                                </div>
                                <div className="order-info-row full-width">
                                    <span>TXID:</span> <span>{order.binance_withdrawal?.txid}</span>
                                </div>
                            </div>
                        </div>
                    )}


                </div>)}
        </>
    );
};

export default OrderDetail;
