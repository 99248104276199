import { useState } from 'react';
import React from 'react';
import '../../static/TermsOfService.css'

const TermsOfService = ({ handleNext }) => {

    const next = () => handleNext()

    const [isChecked, setIsChecked] = useState(false); // 체크박스 상태 추가

    const onChange = () => {
        setIsChecked(!isChecked); // 체크박스 상태 토글
    };

    const isStep0Valid = () => {
        return isChecked && true;
    };

    return (
        <div className='content'>
        <div className="terms-of-service">
            <h2>Bonego Wallet 서비스 이용 약관</h2>
            <p>본 약관은 Bonego Wallet을 통해 제공되는 USDT(테더) 구매 대행 서비스 및 판매 대행 서비스를 이용하는 모든 고객이 반드시 숙지하고 동의해야 하는 사항을 포함하고 있습니다. 이용자는 본 약관에 동의함으로써 아래 모든 조건에 동의한 것으로 간주합니다.</p>
            <h3>1. 공통 규정</h3>
            <ol>
                <li>
                    <strong>자금세탁방지(AML) 및 고객 확인(KYC) 의무</strong>
                    <ul>
                        <li>Bonego Wallet은 자금세탁방지(AML)와 고객 확인(KYC) 절차를 철저히 준수하고 있습니다. 이를 위해 필요 시 고객의 신원 및 자금 출처에 대한 추가 정보를 요청할 수 있으며, 이용자는 이에 협조해야 합니다.</li>
                        <li>모든 서비스 이용자는 Bonego Wallet의 요구에 따라 KYC 및 AML 절차를 이행해야 하며, 이를 거부할 경우 서비스 이용이 제한될 수 있습니다.</li>
                    </ul>
                </li>
                <li>
                    <strong>책임 한계</strong>
                    <ul>
                        <li>Bonego Wallet은 불법적인 자금 거래나 불법 행위와 관련된 거래를 엄격히 금지하며, 모든 법적 책임은 전적으로 이용자에게 있습니다.</li>
                        <li>이용자는 본 서비스를 이용함에 있어 Bonego Wallet이 불법 자금과 관련된 손실이나 법적 문제에 대한 책임을 지지 않음을 인정합니다.</li>
                    </ul>
                </li>
                <li>
                    <strong>자금 출처의 책임</strong>
                    <ul>
                        <li>이용자는 본 서비스에 사용하는 자금이 합법적이며, 본인의 명의임을 보증해야 합니다. 자금 출처와 관련하여 발생하는 법적 문제는 전적으로 이용자의 책임입니다.</li>
                    </ul>
                </li>
                <li>
                    <strong>외환관리법 준수 의무</strong>
                    <ul>
                        <li>Bonego Wallet은 외환 송금 서비스가 아니며, 외환 송금 목적으로 본 서비스를 이용할 수 없습니다. 외환 송금으로 의심되는 거래가 발생할 경우, 회사는 거래를 보류하거나 관련 기관에 보고할 수 있습니다.</li>
                        <li>이용자는 외환관리법을 준수해야 하며, 외환 송금 목적이나 외환 규제를 회피하기 위해 본 서비스를 악용할 경우 발생하는 모든 책임은 이용자 본인에게 있습니다.</li>
                    </ul>
                </li>
            </ol>
            <h3>2. 구매 대행 서비스 이용 약관</h3>
            <ol>
                <li>
                    <strong>거래 취소 불가</strong>
                    <ul>
                        <li>USDT는 암호화폐 특성상 전송 이후 취소가 절대 불가능합니다. 이용자는 전송 전 정보가 정확한지 반드시 확인해야 합니다.</li>
                    </ul>
                </li>
                <li>
                    <strong>전송 시간 안내 및 보상 정책</strong>
                    <ul>
                        <li>전송 시간은 보통 5~10분이 소요되지만, 네트워크 상황에 따라 최대 60분까지 지연될 수 있습니다. Bonego Wallet은 네트워크 지연으로 인한 전송 시간을 보장하지 않습니다. 그러나 회사의 내부 사정으로 지연이 발생한 경우, 환불이 가능합니다.</li>
                    </ul>
                </li>
                <li>
                    <strong>본인 명의의 자금 및 지갑 주소 사용</strong>
                    <ul>
                        <li>이용자는 반드시 본인 명의의 자금을 사용하여 USDT를 구매해야 하며, 본인 명의의 지갑 주소로 USDT를 수령해야 합니다. 제3자의 자금이나 지갑 주소를 사용하는 것은 금지되어 있으며, 이를 위반할 경우 모든 법적 책임은 이용자 본인에게 있습니다.</li>
                    </ul>
                </li>
                <li>
                    <strong>가격 변동 위험</strong>
                    <ul>
                        <li>USDT 구매 후 트랜잭션 진행 중 발생하는 가격 변동에 대해 Bonego Wallet은 책임을 지지 않습니다. 이용자는 암호화폐의 변동성을 이해하고 이를 수락한 것으로 간주됩니다.</li>
                    </ul>
                </li>
                <li>
                    <strong>전송 수수료</strong>
                    <ul>
                        <li>USDT 전송 시 기본 네트워크 수수료가 부과되며, 이는 네트워크 상황에 따라 변동될 수 있습니다.</li>
                    </ul>
                </li>
            </ol>
            <h3>3. 판매 대행 서비스 이용 약관</h3>
            <ol>
                <li>
                    <strong>자산 출처의 명확성</strong>
                    <ul><li>이용자는 본 서비스를 통해 판매하려는 테더가 합법적인 자산임을 보증해야 합니다. 불법적인 활동과 연관된 자산으로 판명될 경우, 이에 대한 모든 책임은 이용자 본인에게 있습니다.</li>
                    </ul>
                </li>
                <li>
                    <strong>추가 확인 절차</strong>
                    <ul>
                        <li>Bonego Wallet은 자금세탁방지(AML) 및 고객 확인(KYC) 절차를 철저히 준수하고 있으며, 필요 시 이용자에게 테더의 출처에 대한 추가 정보를 요청할 수 있습니다.</li>
                        <li>이용자는 이 절차에 적극 협조해야 하며, 정보가 불충분하거나 추가 정보를 제공하지 않을 경우 거래가 거부될 수 있으며, 필요 시 관련 당국에 보고될 수 있습니다.</li>
                    </ul>
                </li>
                <li>
                    <strong>테더의 불법 활동 관련성</strong>
                    <ul>
                        <li>이용자는 판매하는 테더가 불법적인 활동과 관련되지 않았음을 보증해야 하며, 이와 관련된 법적 책임은 전적으로 이용자 본인에게 있습니다.</li>
                        <li>Bonego Wallet은 불법 활동과 연관된 테더 판매 신청을 허용하지 않으며, 의심이 드는 경우 해당 거래를 차단할 권리를 보유합니다.</li>
                    </ul>
                </li>
            </ol>
            <br />
            <h3>본 약관에 대한 동의</h3>
            <p>본인은 위의 모든 사항을 정확히 숙지하고 이해하였으며, 이에 동의합니다.</p>
            <br/>
            <label>
                <input type="checkbox" onChange={onChange} /> 약관에 동의합니다.
            </label>
            <div className="navigation-buttons">
                <button onClick={next} disabled={!isStep0Valid()}>다음</button>
            </div>
        </div>
        </div>
    );
};
export default TermsOfService;