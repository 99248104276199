import React, {useEffect, useState} from "react";
import '../static/Login.css';
import {useNavigate, useSearchParams} from 'react-router-dom';
import usePartnerSignup from "../components/customhook/usePartnerSignup";
import formatPhoneNumber from '../components/customhook/formatPhoneNumber'
import TermsOfService from "../components/partnersignup/TermsOfService";
import Step1 from "../components/partnersignup/Step1";
import Step2 from "../components/partnersignup/Step2";
import Step3 from "../components/partnersignup/Step3";
import Step4 from "../components/partnersignup/Step4";

const PartnerSignup = () => {
    const [step, setStep] = useState(0);  // 현재 단계 관리
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        password: '',
        tradeType: '',
        referral: '',
        binanceApiKey: '',
        binanceSecretKey: '',
        binanceUsdtTrc20Address: '',
        bank: '',
        accountNumber: '',
        accountHolder: '',
    });

    const { partnerSignup, loading, error } = usePartnerSignup();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const referral = searchParams.get('referral') || 'b261b92e';

    const handlePrevious = (option=null) => {
        if (option) {
            setStep(option);
        } else {
            setStep(prev => Math.max(prev - 1, 1))
        }
    };
    const handleNext = (option=null) => {
        if (option) {
            setStep(option);
        } else {
            setStep(prev => Math.min(prev + 1, 5))
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        let newValue = value;

        // 전화번호 필드를 특별히 처리하여 형식화
        if (name === 'phone') {
            const cleaned = ('' + value).replace(/\D/g, ''); // 숫자만 남기기
            if (cleaned.length > 11) {
                return; // 11자리 이상이면 아무 작업도 하지 않음
            }
            newValue = formatPhoneNumber(value);
        }

        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : newValue
        }));
    };

    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            'referral': referral
        }));
    }, [referral]);


    const handleSignup = async () => {
        const res = await partnerSignup(formData);
        if (res) {
            navigate('/login');
        } else {
            alert(error)
        }
    };

    console.log(step)

    return (
        <div className='container'>
            <div className="header">
                <div className="text">Sign Up - Step {step} / 4</div>
                <div className="underline"></div>
            </div>
            {step === 0 && <TermsOfService handleNext={handleNext}/>}
            {step === 1 && <Step1 formData={formData} handleChange={handleChange} handleNext={handleNext} />}
            {step === 2 && <Step2 formData={formData} handleChange={handleChange} handleNext={handleNext} handlePrevious={handlePrevious} />}
            {step === 3 && <Step3 formData={formData} handleChange={handleChange} handleNext={handleNext} handlePrevious={handlePrevious} />}
            {step === 4 && <Step4 formData={formData} handleSignup={handleSignup} loading={loading} handlePrevious={handlePrevious} />}
        </div>
    );
};

export default PartnerSignup;
